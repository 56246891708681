<template>
  <div id="About" v-if="content">
    <div class="mainbox">
      <div class="title">{{ content.title }}</div>
      <div class="row mt-5">
        <div class="leftSide" :class="getSelectedLang === 'en' ? 'col-md-9' : 'col-md-8'">
          <div class="me-text pr-5">
            <div v-if="getSelectedLang === 'en'">
              <article>
                <p>
                  📢 MEVN &amp; MERN Stack 🎩 Vue.js 3 ☄ Nuxt.js 3 🌟 Next.js 14 🌠 React.js 18 💻
                  TypeScript 🦄 Node.js ☃ Express.js 🌸 Pinia 😎 Vuex 🐧 Vuetify ✍ Supabase 🔥
                  Tailwind 🎨 Blogger ❤ Open Source &amp; Clean Code
                </p>
                <p>🚀 MEVN &amp; MERN Stack Developer Extraordinaire! 🌟</p>
                <p>🔥 Frontend Guru (70%) | Backend Adventurer (30%) 🔥</p>
                <p>💡 DRY Code Enthusiast | Clean &amp; Maintainable Code Lover 💻</p>
                <p>💚 Open Source Advocate 🌍</p>
                <p>&nbsp;</p>
                <p>
                  <strong>Hi there! <span>👋</span></strong>
                </p>
                <p>
                  I'm <strong>Mustafa Çağrı</strong>, a developer who specializes in the
                  <strong>MEVN Stack</strong> &amp; <strong>MERN Stack</strong> and currently lives
                  in <strong>Istanbul, Turkey</strong>.
                </p>
                <p>
                  🌍 With a Computer Science Engineering degree from Sabancı University (2011) 🎓,
                  I’ve been crafting code since the days of Classic ASP! '99 💾
                </p>
                <h3 class="mt-5">✨ What I Do Best</h3>
                <p>
                  💻 Frontend: Vue.js is my superpower! Whether it's Vue 2 or Vue 3, I build sleek,
                  responsive, and dynamic UIs. I’m also well-versed in pure JavaScript and jQuery
                  for those extra tricky tasks. Additionally, React.js ofc!
                </p>
                <p>
                  🛠 Backend: Node.js is my backend buddy, with a soft spot for ASP.Net Core and PHP.
                  And yep, I’m comfy with MySQL, MSSQL, MongoDB, PostgreSQL, Supabase, and Firebase
                  too! 🔥
                </p>
                <h3 class="mt-5">🔧 My Stack Arsenal</h3>
                <p>
                  Vue 2, Vue 3, Nuxt 3, Pinia, Vuex, Vue Router, Vuetify, Vuexy, Node.js,
                  Express.js, MongoDB, Tailwind CSS, RESTful APIs, jQuery, Bootstrap, Classic ASP,
                  MySQL, Lodash, i18n, SASS, UX Design, Storybook, Regula (Face Recognition),
                  Socket.io, React.js, Next.js, Redux, Typescript, .NET Core, PHP, OpenAI API, Unit
                  Testing (Jest, Vitest) 🚀
                </p>
                <h3 class="mt-5">🎯 Plus</h3>
                <p>
                  Salesforce, Scrum, JIRA, Asana, Slack, Notion, GitHub, GitLab, CI/CD, MSSQL, SEO,
                  Firebase, Heroku, Netlify, AWS, PagerDuty, Sentry, XCode (iOS Simulator), OpenAI,
                  Figma, VS Code, Postman, SonarCloud, Sonarlint, Adobe Photoshop 🎨
                </p>
                <h3 class="mt-5">🔍 Always Open to New Adventures</h3>
                <p>
                  I’m all about embracing new technologies that push the boundaries and make the
                  current ones seem old-school! I’m ready to dive in and explore whatever tech can
                  bring the best results. 🌐
                </p>
                <h3 class="mt-5">🌟 Why Choose Me?</h3>
                <p>
                  I’m not just about writing code—I’m about crafting elegant, scalable, and
                  user-friendly solutions. I thrive in environments where innovation meets
                  collaboration. Let’s build something amazing together! 🌟
                </p>
                <h3 class="mt-5">🚀 Let’s Get Started!</h3>
                <p>
                  I’m ready to take your Vue.js, React.js, or Node.js projects to the next level.
                  Drop me a message, and let’s create something extraordinary! 🌌
                </p>
              </article>
              <!-- 
              <p>
                📢 MEVN & MERN Stack 🎩 Vue.js 3 ☄ Nuxt.js 3 🌟 Next.js 14 🌠 React.js 18 💻
                TypeScript 🦄 Node.js ☃ Express.js 🌸 Pinia 😎 Vuex 🐧 Vuetify ✍ Supabase 🔥
                Tailwind 🎨 Blogger ❤ Open Source & Clean Code
              </p>

              <p>🚀 MEVN & MERN Stack Developer Extraordinaire! 🌟</p>

              <p>🔥 Frontend Guru (70%) | Backend Adventurer (30%) 🔥</p>

              <p>💡 DRY Code Enthusiast | Clean & Maintainable Code Lover 💻</p>

              <p>💚 Open Source Advocate 🌍</p> -->
            </div>

            <div v-else>
              <p v-for="text in content.texts" :key="text.id">{{ text }}</p>
            </div>
          </div>
        </div>
        <div :class="getSelectedLang === 'en' ? 'col-md-3' : 'col-md-4'">
          <div class="pl-4">
            <ProgressBar
              title="Frontend Developing"
              :percentage="100"
              bg="amethyst"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="Backend Developing"
              :percentage="92"
              bg="info"
              :delay="200"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="Javascript"
              :percentage="95"
              bg="primary"
              :delay="400"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="Typescript"
              :percentage="80"
              bg="#007acc"
              :delay="500"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="Vue 2 & Vue 3"
              :percentage="95"
              bg="success"
              :delay="600"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="UI / UX Design"
              :percentage="90"
              bg="danger"
              :delay="800"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="Pinia"
              :percentage="98"
              bg="success"
              :delay="1000"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="Nuxt"
              :percentage="88"
              customBgColor="#CD5C5C"
              :delay="1200"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="Vuetify"
              :percentage="92"
              customBgColor="#DC143C"
              :delay="1400"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="Vuex"
              :percentage="98"
              bg="info"
              :delay="1600"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="React.js"
              :percentage="82"
              customBgColor="#61DBFB"
              :delay="1800"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="Next.js"
              :percentage="85"
              customBgColor="#000"
              :delay="1900"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="OpenAI & AI"
              :percentage="50"
              customBgColor="#1a7f64"
              :delay="2000"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="Node.js"
              :percentage="88"
              customBgColor="#68A063"
              :delay="2200"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="Express.js"
              :percentage="88"
              customBgColor="#303030"
              :delay="2400"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="Github"
              :percentage="98"
              bg="americanriver"
              :delay="2600"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="DRY"
              :percentage="95"
              :customBgColor="'#f06529'"
              :delay="2800"
              :striped="true"
              :animation="true"
            />
            <ProgressBar
              title="Clean Code Lover"
              :percentage="100"
              :customBgColor="'#FF1493'"
              :delay="3000"
              :striped="true"
              :animation="true"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- mainbox -->
    <div class="infobox">
      <div class="row">
        <div class="col-md-3">
          <i class="fas fa-thumbs-up"></i><label> <Counter end="217" /> </label
          ><span>{{ content.infobox[0] }}</span>
        </div>
        <div class="col-md-3">
          <i class="fas fa-coffee"></i><label> <Counter :end="3624" /> </label
          ><span>{{ content.infobox[1] }}</span>
        </div>
        <div class="col-md-3">
          <i class="fas fa-users"></i><label> <Counter end="129" /> </label
          ><span>{{ content.infobox[2] }}</span>
        </div>
        <div class="col-md-3">
          <i class="fas fa-stopwatch"></i><label> <Counter :end="businessYear" /> </label
          ><span>{{ content.infobox[3] }}</span>
        </div>
      </div>
    </div>
    <!-- infobox -->
    <div class="mainbox mt-5" style="min-height: auto !important">
      <div class="title text-danger">
        I <span class="text-danger"><i class="fas fa-heart"></i></span> Coding
      </div>
      <div class="row mt-4">
        <div class="col-md-3">
          <ProgressBar
            title="Classic ASP"
            :percentage="100"
            bg="turquoise"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="HTML 5"
            :percentage="100"
            customBgColor="#f06529"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="jQuery"
            :percentage="91"
            customBgColor="#0769ad"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="CSS 3"
            :percentage="92"
            customBgColor="#66d3fa"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Tailwind CSS"
            :percentage="91"
            customBgColor="#4338ca"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Bootstrap"
            :percentage="97"
            customBgColor="#563d7c"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="SignalR"
            :percentage="66"
            customBgColor="#7014e8"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Socket.io"
            :percentage="77"
            bg="amethyst"
            :striped="true"
            :animation="true"
          />
        </div>
        <div class="col-md-3">
          <ProgressBar
            title="SEO"
            :percentage="100"
            bg="shymoment"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Wordpress"
            :percentage="76"
            customBgColor="#d54e21"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Figma"
            :percentage="81"
            customBgColor="#ff7262"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Adobe Photoshop"
            :percentage="81"
            customBgColor="#8bc3fc"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Social Media Marketing"
            :percentage="79"
            bg="danger"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Search Engine Marketing"
            :percentage="76"
            bg="warning"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Cloud Services"
            :percentage="76"
            customBgColor="#F0FFFF"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Heroku / Netlify / Vercel..."
            :percentage="93"
            customBgColor="20c6b7"
            :striped="true"
            :animation="true"
          />
        </div>
        <div class="col-md-3">
          <ProgressBar
            title="MySQL"
            :percentage="94"
            customBgColor="#00758F"
            :striped="true"
            :animation="true"
          />
          <ProgressBar title="MSSQL" :percentage="87" bg="pink" :striped="true" :animation="true" />
          <ProgressBar
            title="MongoDB"
            :percentage="91"
            customBgColor="#3FA037"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Supabase"
            :percentage="91"
            customBgColor="#3ecf8e"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Firebase"
            :percentage="84"
            customBgColor="#FFA611"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="ASP.Net Core"
            :percentage="70"
            bg="warning"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="PHP"
            :percentage="83"
            customBgColor="#B0B3D6"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="JSON Rest APIs"
            :percentage="88"
            customBgColor="#FAEBD7"
            :striped="true"
            :animation="true"
          />
        </div>
        <div class="col-md-3">
          <ProgressBar
            title="Arduino"
            :percentage="71"
            bg="info"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Unity - Game Developing"
            :percentage="73"
            bg="brightyarrow"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="CI / CD"
            :percentage="84"
            customBgColor="#8A2BE2"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Jira & Confluence & Asana"
            :percentage="94"
            customBgColor="#00BFFF"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Scrum"
            :percentage="92"
            customBgColor="#B22222"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Slack"
            :percentage="98"
            customBgColor="#2D8CFF"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Lokalise & Localise"
            :percentage="97"
            customBgColor="#FFD700"
            :striped="true"
            :animation="true"
          />
          <ProgressBar
            title="Salesforce"
            :percentage="75"
            customBgColor="#009EDB"
            :striped="true"
            :animation="true"
          />
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- mainbox -->
  </div>
</template>

<style scoped>
.leftSide {
  .avatar {
    display: block;
    max-width: 150px;
    max-height: 150px;
    /* margin: 0 30px 30px 0; */
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .avatar2 {
    display: block;
    float: left;
    max-width: 150px;
    max-height: 150px;
    margin: 0 30px 30px 0;
  }

  .me-text2 {
    display: block;
    float: right;
    width: calc(100% - 180px);
  }
}
</style>

<script>
import Counter from "vue-js-counter";
import ProgressBar from "vue-js-progress";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "About",
  data() {
    return {
      content: {},
    };
  },
  components: {
    Counter,
    ProgressBar,
  },
  computed: {
    businessYear() {
      return new Date().getFullYear() - 2011;
    },
    ...mapGetters(["getAPI", "getSelectedLang"]),
  },
  created() {
    axios
      .get(this.getAPI + "about.json")
      .then((res) => {
        this.content = res.data[this.getSelectedLang];
      })
      .catch((err) => console.log(err));
  },
};
</script>
